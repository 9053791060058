import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateServiceCall } from "../../store/reducers/serviceCalls.reducer";
import { buscarDireccionEnGoogleMaps } from "../../helpers/googleMaps/googleMapsQuery";
import { MdDoneAll, MdEditSquare } from "react-icons/md";
import { SiGooglemaps } from "react-icons/si";
import { headStyles, cellStyles } from "./TableStyles";
import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useDisclosure,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  Flex,
  Text,
  Accordion,
  AccordionItem,
  AccordionButton,
  Select,
  AccordionIcon,
  AccordionPanel,
  Badge,
} from "@chakra-ui/react";
import { showToast } from "../../commons/SweetAlertToast";
import axios from "axios";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
const Tickets = ({ isCompleted }) => {
  const dispatch = useDispatch();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [data, setData] = useState([]);
  const [selectedData, setSelectedData] = useState(null);
  const [editedData, setEditedData] = useState(null);
  const customers = useSelector((state) => state.clients);
  const admins = useSelector((state) => state.admins);
  const [subject, setSubject] = useState("");
  const [customerNotes, setCustomerNotes] = useState("");
  const [internalNotes, setInternalNotes] = useState("");
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const user = useSelector((state) => state.user);
  const [status, setStatus] = useState("Open");

  const findCustomer = (id) => {
    const client = customers.find((cust) => {
      return cust._id === id;
    });
    return client;
  };
  const findAdmin = (id) => {
    const client = admins.find((cust) => {
      return cust._id === id;
    });
    return client;
  };

  useEffect(() => {
    axios.get("https://api.nevtis.com/interaction/tickets/all").then((res) => {
      setData(res.data);
    });
  }, [data]);

  useEffect(() => {
    if (selectedData) {
      setEditedData({ ...selectedData });
    }
  }, [selectedData]);

  const handleEdit = (rowData) => {
    setSelectedData(rowData);
    onOpen();
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    const newValue = type === "checkbox" ? checked : value;
    setEditedData((prevState) => ({
      ...prevState,
      [name]: newValue,
    }));
  };
  const handleSave = () => {
    axios
      .put(`https://api.nevtis.com/interaction/tickets/${editedData._id}`, {
        status: editedData.status,
        customerNotes: editedData.customerNotes,
        internalNotes: editedData.internalNotes,
      })
      .then((resp) => {
        onClose();
        showToast("Ticket created", "success");
      });
  };
  const handleDelete = async () => {
    onClose();
    Swal.fire({
      title: `Delete Ticket?`,
      showCancelButton: true,
      confirmButtonText: "Delete",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(
            `https://api.nevtis.com/interaction/tickets/${editedData._id}`
          )
          .then((resp) => {
            if (resp) {
              Swal.fire("Deleted!", "", "success");
            }
          });
      }
    });
  };
  const handleDone = (rowData) => {
    if (!rowData.billing || !rowData.docs) {
      showToast("Please select docs and billing", "error");
      return;
    }
    const updatedData = { ...rowData, made: true };
    dispatch(
      updateServiceCall({ id: rowData._id, serviceCallData: updatedData })
    );
  };

  const handleQueryAddress = ({ address, city, state }) => {
    const maps = `${address}, ${city}, ${state}, EE. UU.`;
    buscarDireccionEnGoogleMaps(maps);
  };
  const handleSelectChange = (e) => {
    const selectedCustomerId = e.target.value;
    const customer = customers.find((c) => c._id === selectedCustomerId);
    setSelectedCustomer(customer);
  };
  const customerOptions = customers.map((customer) => (
    <option key={customer._id} value={customer._id}>
      {customer.companyName || "Cliente No Encontrado"}
    </option>
  ));

  const handleSubmit = () => {
    axios
      .post("https://api.nevtis.com/interaction/tickets/create", {
        company: selectedCustomer._id,
        email: selectedCustomer.email,
        subject,
        customerNotes,
        internalNotes,
        status,
        user: user._id,
      })
      .then((resp) => {
        showToast("Ticket created", "success");
        setSubject("");
        setStatus("Open");
        setCustomerNotes("");
        setInternalNotes("");
      });
  };
  const getBadgeColor = (status) => {
    switch (status) {
      case "Open":
        return "green";
      case "In Progress":
        return "yellow";
      case "Closed":
        return "red";
      case "Pending":
        return "orange";
      default:
        return "gray"; // Color por defecto si no coincide ningún estado
    }
  };

  return (
    <Box mt={4}>
      <Accordion
        allowToggle
        mt={4}
        border="1px"
        borderColor="gray.200"
        boxShadow="xl"
        rounded="md"
        bg="white"
      >
        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box as="span" flex="1" textAlign="left">
                Create Ticket:
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel>
            <FormControl
              mt={4}
              border="1px"
              borderColor="gray.200"
              p={4}
              rounded="md"
              shadow="md"
            >
              <FormLabel>Ticket:</FormLabel>

              {/* Select Customer, Subject, Status */}
              <Flex mt={4} gap={4}>
                <Box>
                  <Text mb={2}>Select Customer</Text>
                  <Select
                    placeholder="Select customer"
                    onChange={handleSelectChange}
                    w="full" // Ancho completo
                  >
                    {customerOptions}
                  </Select>
                </Box>

                <Box flex="1">
                  <Text mb={2}>Subject</Text>
                  <Input
                    placeholder="Enter subject"
                    value={subject}
                    onChange={(e) => setSubject(e.target.value)}
                    w="full" // Ancho completo
                  />
                </Box>

                <Box flex="1">
                  <Text mb={2}>Status</Text>
                  <Select
                    placeholder="Select status"
                    value={status}
                    onChange={(e) => setStatus(e.target.value)}
                    w="full" // Ancho completo
                  >
                    <option value="Open">Open</option>
                    <option value="In Progress">In Progress</option>
                    <option value="Closed">Closed</option>
                    <option value="Pending">Pending</option>
                  </Select>
                </Box>
              </Flex>

              {/* Customer Notes and Internal Notes */}
              <Flex mt={4} gap={4} w="full">
                <Box flex="1">
                  <Text mb={2}>Customer Notes</Text>
                  <Textarea
                    placeholder="Enter customer notes"
                    value={customerNotes}
                    onChange={(e) => setCustomerNotes(e.target.value)}
                    w="full" // Ancho completo
                  />
                </Box>

                <Box flex="1">
                  <Text mb={2}>Internal Notes</Text>
                  <Textarea
                    placeholder="Enter internal notes"
                    value={internalNotes}
                    onChange={(e) => setInternalNotes(e.target.value)}
                    w="full" // Ancho completo
                  />
                </Box>
              </Flex>

              <Button colorScheme="blue" mt={4} onClick={handleSubmit}>
                Submit Ticket
              </Button>
            </FormControl>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>

      <Table variant="simple" border="1px" borderColor="gray.300">
        <Thead>
          <Tr border="1px" borderColor="gray.300">
            <Th {...headStyles}>Ticket Number</Th>
            <Th {...headStyles}>Status</Th>
            <Th {...headStyles}>Company Name</Th>
            <Th {...headStyles}>Customer Notes</Th>
            <Th {...headStyles}>Internal Notes</Th>
            <Th {...headStyles}>User</Th>
            <Th {...headStyles} width="10VW">
              Actions
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          {data.map((rowData) => (
            <Tr key={rowData._id} border="1px" borderColor="gray.300" p={0}>
              <Td {...cellStyles}>{rowData.ticketNumber}</Td>
              <Td {...cellStyles}>
                <Badge colorScheme={getBadgeColor(rowData.status)}>
                  {rowData.status}
                </Badge>
              </Td>
              <Td {...cellStyles}>
                <Link
                  to={`https://adminspace.nevtis.com/client/${rowData.company}`}
                >
                  {findCustomer(rowData.company).companyName}
                </Link>
              </Td>
              <Td {...cellStyles}>{rowData.customerNotes}</Td>
              <Td {...cellStyles}>{rowData.internalNotes}</Td>
              <Td {...cellStyles}>{findAdmin(rowData.user).name}</Td>

              <Td {...headStyles} p={1} pl={4}>
                <Button
                  colorScheme="orange"
                  size="xs"
                  variant="outline"
                  onClick={() => handleEdit(rowData)}
                >
                  <MdEditSquare />
                </Button>
                {!isCompleted && (
                  <Button
                    colorScheme="orange"
                    size="xs"
                    ml={1}
                    onClick={() => handleDone(rowData)}
                  >
                    <MdDoneAll />
                  </Button>
                )}
                <Button
                  size="xs"
                  colorScheme="orange"
                  variant="outline"
                  ml={1}
                  onClick={() => handleQueryAddress(rowData)}
                >
                  <SiGooglemaps />
                </Button>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit Ticket</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl>
              <FormLabel>Status:</FormLabel>
              <Select
                name="status"
                value={editedData?.status || ""}
                onChange={handleInputChange}
                size="sm"
              >
                <option value="Open">Open</option>
                <option value="In Progress">In Progress</option>
                <option value="Closed">Closed</option>
                <option value="Pending">Pending</option>
              </Select>
            </FormControl>

            <FormControl mt={4}>
              <FormLabel>Customer Notes:</FormLabel>
              <Textarea
                name="customerNotes"
                value={editedData?.customerNotes || ""}
                onChange={handleInputChange}
              />
            </FormControl>

            <FormControl mt={4}>
              <FormLabel>Internal Notes:</FormLabel>
              <Textarea
                name="internalNotes"
                value={editedData?.internalNotes || ""}
                onChange={handleInputChange}
              />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="orange"
              mr={3}
              onClick={handleSave}
              variant="solid"
            >
              Save
            </Button>

            <Button
              colorScheme="red"
              mr={3}
              onClick={handleDelete} // Botón de eliminar
              variant="solid"
            >
              Delete
            </Button>

            <Button variant="ghost" onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default Tickets;
