import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Stack,
  FormLabel,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Center,
  Input,
  FormControl,
  Checkbox,
  Button,
  Box,
  useDisclosure,
  Text,
  Select as SelectChakra,
  useMediaQuery,
  IconButton,
} from "@chakra-ui/react";
import { AddIcon, DeleteIcon } from "@chakra-ui/icons";
import Select from "react-select";
import { createClient } from "../../../store/reducers/client.reducer";
import { showToast } from "../../../commons/SweetAlertToast";

const CreateCompany = () => {
  const [isDesktop] = useMediaQuery("(min-width: 800px)");
  const dispatch = useDispatch();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [sendEmail, setSendEmail] = useState(false);
  const domains = useSelector((state) => state.domains);
  const company = useSelector((state) => state.clients);
  const [error, setError] = useState("");

  const dominiosSinCliente = domains.domains.filter((domain) => {
    return !company.some(
      (cliente) => cliente.domain.domain_uuid === domain.domain_uuid
    );
  });
  const servers = [{ url: "https://serv1.nevtisvoice.com/" }];

  const [formData, setFormData] = useState({
    companyName: "",
    emailTicket: "",
    serverURL: "",
    email: "",
    name: "",
    phones: [""], // Múltiples números de teléfono
    domain: "",
    address: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handlePhoneChange = (index, value) => {
    const numericValue = value.replace(/\D/g, "");
    if (numericValue.length <= 10) {
      const updatedPhones = [...formData.phones];
      updatedPhones[index] = numericValue;
      setFormData((prevFormData) => ({
        ...prevFormData,
        phones: updatedPhones,
      }));
    }
  };

  const addPhoneField = () => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      phones: [...prevFormData.phones, ""], // Añadir un nuevo campo de teléfono
    }));
  };

  const removePhoneField = (index) => {
    setFormData((prevFormData) => {
      const updatedPhones = prevFormData.phones.filter((_, i) => i !== index);
      return {
        ...prevFormData,
        phones: updatedPhones,
      };
    });
  };

  const handleDomainChange = (selectedOption) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      domain: selectedOption ? JSON.stringify(selectedOption) : "",
    }));
  };

  const submit = async () => {
    if (!formData.domain) {
      setError("Please, select a Pbx Url");
      return;
    }

    let domain = JSON.parse(formData.domain);

    const resp = await dispatch(
      createClient({
        ...formData,
        phone: formData.phones[0],
        domain: domain.value,
        role: "company",
        sendEmail,
      })
    );
    if (resp.payload) {
      setError("");
      setFormData({
        companyName: "",
        serverURL: "",
        email: "",
        emailTicket: "",
        name: "",
        phones: [""],
        domain: "",
        address: "",
      });
      onClose();
      showToast("Customer created", "success");
    } else {
      showToast("Error", "error");
    }
  };

  const domainOptions = dominiosSinCliente.map((item) => ({
    value: item,
    label: item.domain_name,
  }));

  return (
    <>
      <Center mt={isDesktop ? 10 : 5}>
        <Button
          leftIcon={<AddIcon />}
          colorScheme="teal"
          bg="rgb(255,134,0)"
          _hover={{ bg: "rgba(255,134,0,0.5)" }}
          onClick={onOpen}
          size="xs"
        >
          Add
        </Button>
      </Center>
      <Modal isOpen={isOpen} onClose={onClose} size={"7xl"}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader
            textAlign={"center"}
            color={"nevtis.1"}
            fontSize={"2xl"}
            fontWeight={"bold"}
          >
            Create a new Customer
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box style={{ width: "100%" }}>
              <Stack spacing={2} width={"full"}>
                <Box mb={10}>
                  <Checkbox
                    colorScheme="orange"
                    ml={5}
                    isChecked={sendEmail}
                    onChange={(e) => setSendEmail(e.target.checked)}
                  >
                    Send activation email?
                  </Checkbox>
                </Box>
                <Stack direction="row" spacing={4}>
                  {sendEmail && (
                    <FormControl isRequired>
                      <FormLabel style={formLabelStyle}>Email</FormLabel>
                      <Input
                        name="email"
                        value={formData.email}
                        onChange={handleInputChange}
                      />
                    </FormControl>
                  )}
                  <FormControl isRequired>
                    <FormLabel style={formLabelStyle}>Company Name</FormLabel>
                    <Input
                      name="companyName"
                      value={formData.companyName}
                      onChange={handleInputChange}
                    />
                  </FormControl>
                  <FormControl isRequired>
                    <FormLabel style={formLabelStyle}>Contact</FormLabel>
                    <Input
                      name="name"
                      value={formData.name}
                      onChange={handleInputChange}
                    />
                  </FormControl>
                  <FormControl isRequired>
                    <FormLabel style={formLabelStyle}>Address</FormLabel>
                    <Input
                      name="address"
                      value={formData.address}
                      onChange={handleInputChange}
                    />
                  </FormControl>
                </Stack>

                {/* Números de teléfono múltiples */}
                <FormControl isRequired>
                  <FormLabel style={formLabelStyle}>Phone Numbers</FormLabel>
                  {formData.phones.map((phone, index) => (
                    <Box key={index} display="flex" alignItems="center" mb={2}>
                      <Input
                        value={phone}
                        onChange={(e) =>
                          handlePhoneChange(index, e.target.value)
                        }
                        placeholder="+1 (xxx) xxx-xxxx"
                        type="tel"
                        maxLength={10}
                        pattern="[0-9]*"
                        inputMode="numeric"
                        isRequired
                        pl="3rem"
                      />
                      <Box
                        position="absolute"
                        left="1rem"
                        color="gray.500"
                        zIndex={1}
                      >
                        +1
                      </Box>
                      <IconButton
                        aria-label="Remove phone"
                        icon={<DeleteIcon />}
                        ml={2}
                        onClick={() => removePhoneField(index)}
                        isDisabled={formData.phones.length === 1}
                      />
                    </Box>
                  ))}
                  <Button
                    leftIcon={<AddIcon />}
                    onClick={addPhoneField}
                    size="sm"
                    colorScheme="teal"
                  >
                    Add another phone
                  </Button>
                </FormControl>

                <Stack direction="row" spacing={4}>
                  <FormControl isRequired>
                    <FormLabel style={formLabelStyle}>Ticket Email</FormLabel>
                    <Input
                      name="emailTicket"
                      value={formData.emailTicket}
                      onChange={handleInputChange}
                    />
                  </FormControl>
                  <FormControl isRequired>
                    <FormLabel style={formLabelStyle}>PBX URL</FormLabel>
                    <Select
                      name="domain"
                      options={domainOptions}
                      onChange={handleDomainChange}
                      value={domainOptions.find((option) => option.domain_name)}
                      isClearable
                      placeholder="Select or search..."
                    />
                  </FormControl>

                  <FormControl isRequired>
                    <FormLabel style={formLabelStyle}>Server URL</FormLabel>
                    <SelectChakra
                      onChange={handleInputChange}
                      name="serverURL"
                      value={formData.serverURL}
                    >
                      <option value="">Seleccione</option>
                      {servers.map((item, key) => (
                        <option value={item.url} key={key}>
                          {item.url}
                        </option>
                      ))}
                    </SelectChakra>
                  </FormControl>
                </Stack>
              </Stack>
            </Box>

            <Text color="red">{error}</Text>
            <Center mt={10} mb={4}>
              <Button
                onClick={submit}
                type="submit"
                bg={"nevtis.1"}
                _hover={{ bg: "nevtis.1.4" }}
                color={"white"}
              >
                Submit
              </Button>
            </Center>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

const formLabelStyle = {
  color: "rgba(255,134,0)",
  fontWeight: "bold",
};

export default CreateCompany;
